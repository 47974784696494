import axios from 'axios';
import { settingDataFinal } from '../types/DataSource';
import { KAPTAIN_URL } from './config';

export const getSettings = async (): Promise<settingDataFinal> => {
    const response = await axios.get<settingDataFinal>(`${KAPTAIN_URL}/configuration/sys/get/info`);
    return response.data;
};

export const getFileHas = async (fileName: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get<any>(`${KAPTAIN_URL}/configuration/sys/has/file/kaptain/${fileName}`)
        .then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    });
};

export const updateSettings = async (input: settingDataFinal) => {
    const response = await axios.put<{ status: string }>(`${KAPTAIN_URL}/configuration/sys/update/info`, input);
    return response.data.status;
};

export const restartServer = async () => {
    await axios.post(`${KAPTAIN_URL}/configuration/sys/restart/service`);
};
  
