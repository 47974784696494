import axios from 'axios';
import { Kapsule } from '../types/Kapsule';
import { formatToLocale, stringToUTC } from '../utils/dateUtils';
import { KAPTAIN_URL } from './config';

export const getKapsules = async (): Promise<Kapsule[]> => {
  const response = await axios.get<Kapsule[]>(`${KAPTAIN_URL}/api/kapsule`);
  const raw = response.data;
  return raw.map((item) => {
    return {
      ...item,
      createdAt: item.createdAt && formatToLocale(stringToUTC(item.createdAt), 'yyyy-MM-dd HH:mm:ss'),
      updatedAt: item.updatedAt && formatToLocale(stringToUTC(item.updatedAt), 'yyyy-MM-dd HH:mm:ss'),
    };
  });
};

export const deleteKapsules = async (kapsuleIds: string[]): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios.delete(`${KAPTAIN_URL}/api/kapsule/sql`, {
      data: { kapsuleIds },
    })
    .then((res) => {
      resolve(res)
    })
    .catch((err) => {
      reject(err)
    })
  });
};

export const upgradeGranulesForKapsules = async (kapsuleIds: string[]): Promise<void> => {
    await axios.put(`${KAPTAIN_URL}/api/kapsule/granules/convert`, {
      kapsuleIds,
    })
};

export const exportKapsules = async (kapsuleIds: string[]): Promise<void> => {
  window.location.href=`${KAPTAIN_URL}/api/kapsule/export/kapsules?kapsuleIds=${kapsuleIds.join(',')}`
};

export const exportKapsule = async (kapsuleId: string): Promise<void> => {
  window.location.href=`${KAPTAIN_URL}/api/kapsule/export/kapsule?kapsuleId=${kapsuleId}`
};
