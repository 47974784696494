import { Table } from 'antd';
import React, { FunctionComponent } from 'react';
import { KapsuleField } from '../../types/Kapsule';

type KapsuleFieldsTableProps = {
  fields: KapsuleField[];
};

const columns = [
  { title: 'Name', dataIndex: 'name' },
  { title: 'Data Type', dataIndex: 'type' },
];

export const KapsuleFieldsTable: FunctionComponent<KapsuleFieldsTableProps> = ({ fields }) => {
  return <Table rowKey={(record) => record._id} pagination={false} columns={columns} dataSource={fields} />;
};
