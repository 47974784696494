import React, { FunctionComponent, useEffect, useState } from 'react';
import { Input, InputNumber, Button, Row, Col, Form, Switch, Select, Upload, Anchor, BackTop, Modal, Spin, Tabs, Space, message, Tooltip, Checkbox } from 'antd';
import { PlusCircleOutlined, UploadOutlined, CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FORM_LAYOUT_8_10, FORM_LAYOUT_8_16, FORM_LAYOUT_OFFSET_8_10, FORM_VALIDATE_MESSAGES } from '../../types/config';
import { CERT, DataSource, DESKTOP, JWT, SAAS, settingDataFinal } from '../../types/DataSource';
import { LabeledValue } from 'antd/lib/select';
import { getFileHas, getSettings, restartServer, updateSettings } from '../../services/SettingCompService';
import { KAPTAIN_URL } from '../../services/config';
import './SettingComp.css'
import { InternalNamePath } from 'antd/lib/form/interface';
const { Option } = Select;
const { Link } = Anchor;
const { TabPane } = Tabs;
type SettingProps = {};

const helpText: string = 'Service Port can only be modified manually in config file: default.json under Kaptain folder. A restart of Kaptain Server Service is required';

export const SettingComp: FunctionComponent<SettingProps> = () => {

  const [dataSourceList, setDataSourceList] = useState<DataSource>({});
  const [mode, setMode] = useState<string>('CERT');

  const [isDataSourceModalVisible, setIsDataSourceModalVisible] = useState(false);
  const [usePfxCertificateDisabled, setUsePfxCertificateDisabled] = useState(false);
  const [pfxPasswordShow, setPfxPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState<string>('Loading...');

  const [serverPEMHas, setServerPEMHas] = useState<string>();
  const [serverKeyPEMHas, setServerKeyPEMHas] = useState<string>();
  const [serverPFXHas, setServerPFXHas] = useState<string>();

  const [certFileListShow, setCertFileListShow] = useState<{
    certFile: string,
    keyFile: string,
    caFile: string
  }>({
    certFile: '',
    keyFile: '',
    caFile: ''
  });
  const [jwtFileListShow, setJwtFileListShow] = useState<{
    keyFile: string
  }>({
    keyFile: ''
  });

  const [dataSourceForm] = Form.useForm<{
    dataSourceType: string;
  }>();

  const [settingForm] = Form.useForm();

  useEffect(() => {
    reloadData(settingForm)
  }, [settingForm]);

  const reloadData = (settingForm: any) => {
    setLoading(true)
    //get data
    getSettings().then((res) => {
      try {
        const ds: DataSource = {
          mssql: res.mssql,
          oracle: res.oracle,
          mysql: res.mysql,
          postgresql: res.postgresql,
          snowflake: res.snowflake
        }
        setDataSourceList(ds)
        
        // In order to be compatible with old customers, the 'mode' field needs to be processed;
        const host = (res.hosts as any)[res.settings.qlikHost] as (CERT | JWT | SAAS | DESKTOP)
        const hostMode = host.mode as ('CERT' | 'JWT' | 'SAAS' | 'DESKTOP');
        setMode(hostMode);
        (res.hosts as any)[hostMode] = host
        res.settings.qlikHost = hostMode

        // show fileList UI
        if (hostMode == 'CERT') {
          setCertFileListShow({
            certFile: (host as CERT).certFile ? (host as CERT).certFile.split('\/')[1] : '',
            keyFile: (host as CERT).keyFile ? (host as CERT).keyFile.split('\/')[1] : '',
            caFile: (host as CERT).caFile ? (host as CERT).caFile.split('\/')[1] : ''
          })
        } else if (hostMode == 'JWT') {
          setJwtFileListShow({
            keyFile: (host as JWT).keyFile ? (host as JWT).keyFile.split('\/')[1] : ''
          })
        }

        // if https is false
        if (!res.settings.https) {
          setUsePfxCertificateDisabled(true)
        }
        if (res.settings.https && res.settings.usePfxCertificate) {
          setPfxPasswordShow(false);
        } else {
          setPfxPasswordShow(true);
        }

        // server kaptain files has?
        getFileHas('server.pfx').then(() => {
          setServerPFXHas('server.pfx')
          settingForm.setFieldsValue({
            'temp-serverPFXHas': 'server.pfx'
          })
        }).catch(() => {
          setServerPFXHas(undefined)
        })
        getFileHas('server.pem').then(() => {
          setServerPEMHas('server.pem')
          settingForm.setFieldsValue({
            'temp-serverPEMHas': 'server.pem'
          })
        }).catch(() => {
          setServerPEMHas(undefined)
        })
        getFileHas('server_key.pem').then(() => {
          setServerKeyPEMHas('server_key.pem')
          settingForm.setFieldsValue({
            'temp-serverKeyPEMHas': 'server_key.pem'
          })
        }).catch(() => {
          setServerKeyPEMHas(undefined)
        })

        settingForm.setFieldsValue(res)
        setLoading(false)
      } catch (e) {
        setLoadingText('Something Error!')
      }
    }).catch(() => {
      setLoadingText(`Something Error!`)
    })
  }

  const handleHttpsChange = (e: boolean) => {
    if (!e) {
      settingForm.setFieldsValue({
        settings: {
          usePfxCertificate: false
        }
      })
      setUsePfxCertificateDisabled(true)

      settingForm.setFieldsValue({
        settings: {
          pfxPassword: ''
        }
      })
      setPfxPasswordShow(true)
    } else {
      setUsePfxCertificateDisabled(false)
    }
  }

  const handleUsePfxCertificateChange = (e: boolean) => {
    if (e) {
      setPfxPasswordShow(false)
    } else {
      settingForm.setFieldsValue({
        settings: {
          pfxPassword: ''
        }
      })
      setPfxPasswordShow(true)
    }
  }

  const importPropsCertCertFile = {
    name: 'file',
    accept: '.pem',
    action: `${KAPTAIN_URL}/configuration/sys/import/file`,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    showUploadList: false,
    onChange: async (info: any) => {
      if (info.file.status === 'done') {
        message.success('Uploaded successfully!')
        settingForm.setFieldsValue({
          hosts: {
            CERT: {
              certFile: info.file.response
            }
          }
        })
        setCertFileListShow({
          ...certFileListShow,
          certFile: String(info.file.response ? info.file.response.split('\/')[1] : '')
        })
      } else if (info.file.status === 'error') {
        message.error(info.file.response.message)
      }
    },
  };
  const importPropsCertKeyFile = {
    name: 'file',
    accept: '.pem',
    action: `${KAPTAIN_URL}/configuration/sys/import/file`,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    showUploadList: false,
    onChange: async (info: any) => {
      if (info.file.status === 'done') {
        message.success('Uploaded successfully!')
        settingForm.setFieldsValue({
          hosts: {
            CERT: {
              keyFile: info.file.response
            }
          }
        })
        setCertFileListShow({
          ...certFileListShow,
          keyFile: String(info.file.response ? info.file.response.split('\/')[1] : '')
        })
      } else if (info.file.status === 'error') {
        message.error(info.file.response.message)
      }
    },
  };
  const importPropsCertCaFile = {
    name: 'file',
    accept: '.pem',
    action: `${KAPTAIN_URL}/configuration/sys/import/file`,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    showUploadList: false,
    onChange: async (info: any) => {
      if (info.file.status === 'done') {
        message.success('Uploaded successfully!')
        settingForm.setFieldsValue({
          hosts: {
            CERT: {
              caFile: info.file.response
            }
          }
        })
        setCertFileListShow({
          ...certFileListShow,
          caFile: String(info.file.response ? info.file.response.split('\/')[1] : '')
        })
      } else if (info.file.status === 'error') {
        message.error(info.file.response.message)
      }
    },
  };
  const importPropsJWTKeyFile = {
    name: 'file',
    accept: '.pem',
    action: `${KAPTAIN_URL}/configuration/sys/import/file`,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    showUploadList: false,
    onChange: async (info: any) => {
      if (info.file.status === 'done') {
        message.success('Uploaded successfully!')
        settingForm.setFieldsValue({
          hosts: {
            JWT: {
              keyFile: info.file.response
            }
          }
        })
        setJwtFileListShow({
          ...jwtFileListShow,
          keyFile: String(info.file.response ? info.file.response.split('\/')[1] : '')
        })
      } else if (info.file.status === 'error') {
        message.error(info.file.response.message)
      }
    },
  };

  /**
   * server.pfx && server_key.pem && server.pem
   */
  const importPropsServerFile = {
    name: 'file',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    showUploadList: false,
    onChange: async (info: any) => {
      if (info.file.status === 'done') {
        message.success('Uploaded successfully!')
        if (info.file.response === 'server.pfx') {
          setServerPFXHas('server.pfx')
          settingForm.setFieldsValue({
            'temp-serverPFXHas': 'server.pfx'
          })
        }
        if (info.file.response === 'server.pem') {
          setServerPEMHas('server.pem')
          settingForm.setFieldsValue({
            'temp-serverPEMHas': 'server.pem'
          })
        }
        if (info.file.response === 'server_key.pem') {
          setServerKeyPEMHas('server_key.pem');
          settingForm.setFieldsValue({
            'temp-serverKeyPEMHas': 'server_key.pem'
          })
        }
      } else if (info.file.status === 'error') {
        message.error(info.file.response.message)
      }
    },
  };

  const CERTModeComp = () => {
    return (
      <>
      <Form.Item
        label="Mode"
        name={['hosts', 'CERT', 'mode']}
        initialValue={'CERT'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Schema"
        name={['hosts', 'CERT', 'schema']}
        initialValue={'enigma.js/schemas/12.20.0.json'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Qlik Hostname"
        name={['hosts', 'CERT', 'hostname']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Qlik Virtual Proxy"
        name={['hosts', 'CERT','prefix']}
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Use HTTPS"
        name={['hosts', 'CERT', 'isSecure']}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="userIdAttr"
        name={['hosts', 'CERT', 'userIdAttr']}
        initialValue={'userId'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="userDirectoryAttr"
        name={['hosts', 'CERT', 'userDirectoryAttr']}
        initialValue={'userDirectory'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>

      {/* insert part：before-> system settings, after-> CERT mode */}
      <Form.Item
        label="Qlik User ID for Kaptain"
        name={['settings', 'userId']}
        rules={[{ required: true }]}
      >
        {/* (only when qlikHost.mode = “CERT”) */}
        <Input />
      </Form.Item>
      <Form.Item
        label="Qlik User Directory for Kaptain"
        name={['settings', 'userDirectory']}
        rules={[{ required: true }]}
      >
        {/* (only when qlikHost.mode = “CERT”) */}
        <Input />
      </Form.Item>
      {/* insert part end */}

      <Form.Item
        label="Qlik Client Certificate"
      >
        <Form.Item
          name={['hosts', 'CERT', 'certFile']}
          noStyle
          label="Qlik Client Certificate"
          rules={[{ required: true }]}
        >
          <Input className='formItemHiddenStyle' />
        </Form.Item>
        <Form.Item
          noStyle
        >
          <Upload {...importPropsCertCertFile} listType="text" maxCount={1}><Button icon={<UploadOutlined />}>upload</Button></Upload>
          {
            certFileListShow?.certFile
            ? <div className='fileListShow'><CheckCircleOutlined style={{marginRight: 7.5, color: '#52c41a'}}/>{certFileListShow?.certFile}</div>
            : null
          }
        </Form.Item>
      </Form.Item>

      <Form.Item
        label="Qlik Client Certificate Key"
      >
        <Form.Item
          name={['hosts', 'CERT', 'keyFile']}
          noStyle
          rules={[{ required: true }]}
          label="Qlik Client Certificate Key"
        >
          <Input className='formItemHiddenStyle' />
        </Form.Item>
        <Form.Item
          noStyle
        >
          <Upload {...importPropsCertKeyFile} listType="text" maxCount={1}><Button icon={<UploadOutlined />}>upload</Button></Upload>
          {
            certFileListShow?.keyFile
            ? <div className='fileListShow'><CheckCircleOutlined style={{marginRight: 7.5, color: '#52c41a'}}/>{certFileListShow?.keyFile}</div>
            : null
          }
        </Form.Item>
      </Form.Item>

      <Form.Item
        label="Qlik Root Certificate"
      >
        <Form.Item
          name={['hosts', 'CERT', 'caFile']}
          noStyle
          label="Qlik Root Certificate"
          rules={[{ required: true }]}
        >
          <Input className='formItemHiddenStyle' />
        </Form.Item>
        <Form.Item
          noStyle
        >
          <Upload {...importPropsCertCaFile} listType="text" maxCount={1}><Button icon={<UploadOutlined />}>upload</Button></Upload>
          {
            certFileListShow?.caFile
            ? <div className='fileListShow'><CheckCircleOutlined style={{marginRight: 7.5, color: '#52c41a'}}/>{certFileListShow?.caFile}</div>
            : null
          }
        </Form.Item>
      </Form.Item>
      </>
    )
  }

  const JWTModeComp = () => {
    return (
      <>
      <Form.Item
        label="Mode"
        name={['hosts', 'JWT', 'mode']}
        initialValue={'JWT'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Schema"
        name={['hosts', 'JWT', 'schema']}
        initialValue={'enigma.js/schemas/12.20.0.json'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Qlik Hostname"
        name={['hosts', 'JWT', 'hostname']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Qlik Virtual Proxy"
        name={['hosts', 'JWT', 'prefix']}
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Use HTTPS"
        name={['hosts', 'JWT', 'isSecure']}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="userIdAttr"
        name={['hosts', 'JWT', 'userIdAttr']}
        initialValue={'userId'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="userDirectoryAttr"
        name={['hosts', 'JWT', 'userDirectoryAttr']}
        initialValue={'userDirectory'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>

      <Form.Item
        label="Qlik JWT Key"
      >
        <Form.Item  
          name={['hosts', 'JWT', 'keyFile']}
          noStyle
          rules={[{ required: true }]}
          label="Qlik JWT Key"
        >
          <Input className='formItemHiddenStyle' />
        </Form.Item>
        <Form.Item
          noStyle
        >
          <Upload {...importPropsJWTKeyFile} listType="text" maxCount={1}><Button icon={<UploadOutlined />}>upload</Button></Upload>
          {
            jwtFileListShow?.keyFile
            ? <div className='fileListShow'><CheckCircleOutlined style={{marginRight: 7.5, color: '#52c41a'}}/>{jwtFileListShow?.keyFile}</div>
            : null
          }
        </Form.Item>
      </Form.Item>
      </>
    )
  }

  const SAASModeComp = () => {
    return (
      <>
      <Form.Item
        label="Mode"
        name={['hosts', 'SAAS', 'mode']}
        initialValue={'SAAS'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Schema"
        name={['hosts', 'SAAS', 'schema']}
        initialValue={'enigma.js/schemas/12.20.0.json'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Qlik SaaS Tenant hostname"
        name={['hosts', 'SAAS', 'hostname']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Qlik SaaS API Key"
        name={['hosts', 'SAAS', 'apiKey']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      </>
    )
  }

  const DESKTOPModeComp = () => {
    return (
      <>
      <Form.Item
        label="Mode"
        name={['hosts', 'DESKTOP', 'mode']}
        initialValue={'DESKTOP'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Schema"
        name={['hosts', 'DESKTOP', 'schema']}
        initialValue={'enigma.js/schemas/12.20.0.json'}
        hidden
      >
        {/* (hidden & default) */}
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Hostname"
        name={['hosts', 'DESKTOP', 'hostname']}
        initialValue={'localhost:9076'}
        hidden
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Prefix"
        name={['hosts', 'DESKTOP', 'prefix']}
        initialValue={''}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="IsSecure"
        name={['hosts', 'DESKTOP', 'isSecure']}
        initialValue={false}
        valuePropName="checked"
        hidden
      >
        <Switch />
      </Form.Item>
      </>
    )
  }

  const showModeItem = () => {
    switch (mode) {
      case 'CERT':
        return CERTModeComp();
      case 'JWT':
        return JWTModeComp();
      case 'SAAS':
        return SAASModeComp();
      case 'DESKTOP':
        return DESKTOPModeComp();
      default:
        return (
          <p>something error！</p>
        )
    }
  }

  const MSSQLDBComp = () => {
    return (
      <TabPane tab={`MS SQL Server`} key="mssql" forceRender={true}>
      <Form.Item
        label="Server"
        name={['mssql', 'MSSQL_SERVER']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Port"
        name={['mssql', 'MSSQL_PORT']}
        rules={[{ required: true }, { type: 'number' }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="User"
        name={['mssql', 'MSSQL_USER']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name={['mssql', 'MSSQL_PASSWORD']}
        rules={[{ required: false }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Database"
        name={['mssql', 'MSSQL_DATABASE']}
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Encrypt"
        name={['mssql', 'SQL_ENCRYPT']}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Driver"
        name={['mssql', 'DRIVER']}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Options" style={{fontWeight: 500}} />
      <Form.Item
        label="Encrypt"
        name={['mssql', 'OPTIONS', 'encrypt']}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="EnableArithAbort"
        name={['mssql', 'OPTIONS', 'enableArithAbort']}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="TrustedConnection"
        name={['mssql', 'OPTIONS', 'trustedConnection']}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Trust Server Certificate"
        name={['mssql', 'OPTIONS', 'trustServerCertificate']}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      </TabPane>
    )
  }

  const ORACLEDBComp = () => {
    return (
      <TabPane tab={`Oracle`} key="oracle" forceRender={true}>
      <Form.Item
        label="Server"
        name={['oracle', 'ORACLE_SERVER']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="LIB"
        name={['oracle', 'ORACLE_LIB']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Port"
        name={['oracle', 'ORACLE_PORT']}
        rules={[{ required: true }, { type: 'number' }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="User"
        name={['oracle', 'ORACLE_USER']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name={['oracle', 'ORACLE_PASSWORD']}
        rules={[{ required: true }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Database"
        name={['oracle', 'ORACLE_DATABASE']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      </TabPane>
    )
  }

  const MYSQLDBComp = () => {
    return (
      <TabPane tab={`MySQL`} key="mysql" forceRender={true}>
      <Form.Item
        label="Pool"
        name={['mysql', 'MYSQL_POOL']}
        rules={[{ required: true }, { type: 'number' }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="Server"
        name={['mysql', 'MYSQL_SERVER']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Port"
        name={['mysql', 'MYSQL_PORT']}
        rules={[{ required: true }, { type: 'number' }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="User"
        name={['mysql', 'MYSQL_USER']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name={['mysql', 'MYSQL_PASSWORD']}
        rules={[{ required: true }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Database"
        name={['mysql', 'MYSQL_DATABASE']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      </TabPane>
    )
  }

  const POSTGREDBComp = () => {
    return (
      <TabPane tab={`PostgreSQL`} key="postgresql" forceRender={true}>
      <Form.Item
        label="User"
        name={['postgresql', 'POSTGRE_USER']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Server"
        name={['postgresql', 'POSTGRE_SERVER']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Database"
        name={['postgresql', 'POSTGRE_DATABASE']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name={['postgresql', 'POSTGRE_PASSWORD']}
        rules={[{ required: true }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Port"
        name={['postgresql', 'POSTGRE_PORT']}
        rules={[{ required: true }, { type: 'number' }]}
      >
        <InputNumber />
      </Form.Item>
      </TabPane>
    )
  }

  const SNOWFLAKEDBComp = () => {
    return (
      <TabPane tab={`Snowflake`} key="snowflake" forceRender={true}>
      <Form.Item
        label="Account"
        name={['snowflake', 'SNOWFLAKE_ACCOUNT']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Username"
        name={['snowflake', 'SNOWFLAKE_USERNAME']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Database"
        name={['snowflake', 'SNOWFLAKE_DATABASE']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name={['snowflake', 'SNOWFLAKE_PASSWORD']}
        rules={[{ required: true }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Schema"
        name={['snowflake', 'SNOWFLAKE_SCHEMA']}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      </TabPane>
    )
  }

  const renderDBItem = (db: string) => {
    switch (db) {
      case 'mssql':
        return MSSQLDBComp();
      case 'oracle':
        return ORACLEDBComp();
      case 'mysql':
        return MYSQLDBComp();
      case 'postgresql':
        return POSTGREDBComp();
      case 'snowflake':
        return SNOWFLAKEDBComp();
      default:
        return (
          <p>something error！</p>
        )
    }
  }

  const handleDataSourceModalCancel = () => {
    setIsDataSourceModalVisible(false);
  };

  const handleDataSourceModalOk = async () => {
    await dataSourceForm.validateFields();
    const { dataSourceType } = dataSourceForm.getFieldsValue();
    switch (dataSourceType) {
      case 'mssql':
        const MSSQL_CONFIG = {
          "MSSQL_SERVER": "",
          "MSSQL_PORT": 1433,
          "MSSQL_USER": "",
          "MSSQL_PASSWORD": "",
          "MSSQL_DATABASE": "",
          "SQL_ENCRYPT": false,
          "DRIVER": "",
          "OPTIONS": {
            "encrypt": false,
            "enableArithAbort": false,
            "trustedConnection": false,
            "trustServerCertificate": false
          }
        }
        setDataSourceList(v => {
          v.mssql = MSSQL_CONFIG;
          return v;
        })
        break;
      case 'mysql':
        const MYSQL_CONFIG = {
          "MYSQL_POOL": 0,
          "MYSQL_SERVER": "",
          "MYSQL_PORT": 3306,
          "MYSQL_USER": "",
          "MYSQL_PASSWORD": "",
          "MYSQL_DATABASE": ""
        }
        setDataSourceList(v => {
          v.mysql = MYSQL_CONFIG;
          return v;
        })
        break;
      case 'oracle':
        const ORACLE_CONFIG = {
          "ORACLE_SERVER": "",
          "ORACLE_LIB": "",
          "ORACLE_PORT": 1521,
          "ORACLE_USER": "",
          "ORACLE_PASSWORD": "",
          "ORACLE_DATABASE": ""
        }
        setDataSourceList(v => {
          v.oracle = ORACLE_CONFIG;
          return v;
        })
        break;
      case 'postgresql':
        const POSTGRE_CONFIG = {
          "POSTGRE_USER": "",
          "POSTGRE_SERVER": "",
          "POSTGRE_DATABASE": "",
          "POSTGRE_PASSWORD": "",
          "POSTGRE_PORT": 5432
        }
        setDataSourceList(v => {
          v.postgresql = POSTGRE_CONFIG;
          return v;
        })
        break;
      case 'snowflake':
        const SNOWFLAKE_CONFIG = {
          "SNOWFLAKE_ACCOUNT": "",
          "SNOWFLAKE_USERNAME": "",
          "SNOWFLAKE_PASSWORD": "",
          "SNOWFLAKE_DATABASE": "",
          "SNOWFLAKE_SCHEMA": "",
        }
        setDataSourceList(v => {
          v.snowflake = SNOWFLAKE_CONFIG;
          return v;
        })
        break;
      default:
        alert('Error: No dataSourceType!')
        break;
    }
    setIsDataSourceModalVisible(false);
  };

  const onEdit = async (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: "add" | "remove") => {
    if (action == 'remove') {
      const data = {...dataSourceList};
      delete (data as any)[e.toString()]
      setDataSourceList(data);
    }
  }

  /**
   * submit form
   */
  const handleFormSubmit = async () => {
    Modal.confirm({
      width: 550,
      title: 'Confirm',
      content: <span>Are you sure to save the changes?<br/>Kaptain Service will be restarted automatically, may take a while...</span>,
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: async () => {
        setLoading(true)
        const formData: settingDataFinal = settingForm.getFieldsValue();
        // remove useless key;
        Object.keys(formData).map((key) => {
          if (key.includes('temp-')) {
            delete (formData as any)[key];
          }
        });
        const res = await updateSettings(formData);
        if (res == 'success') {
          restartServer()
          message.success({
            content: <span>Saved successfully, Kaptain Service is restarting...<br/>The page will automatically refresh after <span style={{fontWeight: 700}}>3</span> seconds.</span>,
            onClose: function(){
              window.location.reload();
              document.body.scrollIntoView();
            }
          })
        } else {
          message.error('Error saving!')
          setLoading(false)
          return false;
        }
      },
    });
  }

  const handleShowValidateMsg = async (errorFields: { name: InternalNamePath; errors: string[]; }[]) => {
    message.error(errorFields[0].errors[0]);
  }

  const handleSelectMode = async (value: string | number | LabeledValue) => {
    setMode(value.toString())
  }

  return (
    <Spin size={'large'} tip={loadingText} spinning={loading}>
    <Row><b>Settings</b></Row>
    <Row>
      <Col span={16}>
        <Form
          autoComplete="off"
          style={{textAlign: 'left'}}
          form={settingForm}
          scrollToFirstError={true}
          onFinish={() => {handleFormSubmit()}}
          onFinishFailed={({errorFields}) => handleShowValidateMsg(errorFields)}
          validateMessages={FORM_VALIDATE_MESSAGES}
          requiredMark={false}
          {...FORM_LAYOUT_8_10}
        >
        {/* Part 1 */}
          <Form.Item label={<span id='system-settings'>System</span>} style={{fontWeight: 700}} />
          <Form.Item
            label='Kaptain Port'
            rules={[{ required: true }, { type: 'number' }]}
          >
            <Form.Item
              name={['settings', 'port']}
              noStyle
            >
              <InputNumber disabled />
            </Form.Item>
            <Form.Item
              noStyle
            >
              <Tooltip title={helpText}>
                <QuestionCircleOutlined className='helpIcon'/>
              </Tooltip>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={<span>Use HTTPS mode</span>}
            name={['settings', 'https']}
            valuePropName="checked"
          >
            <Switch onChange={(e) => {handleHttpsChange(e)}} />
          </Form.Item>
          <Form.Item
            label={<span>Use PFX Certificate</span>}
            name={['settings', 'usePfxCertificate']}
            valuePropName="checked"
          >
            {/* (false, only when https = true) */}
            <Switch disabled={usePfxCertificateDisabled}  onChange={(e) => {handleUsePfxCertificateChange(e)}} />
          </Form.Item>

          {/* server.pfx */}
          {!usePfxCertificateDisabled && !pfxPasswordShow ? <Form.Item label="Server PFX File">
            <Form.Item
              label="Server PFX File"
              name="temp-serverPFXHas"
              noStyle
              rules={[{ required: true }]}
            >
              <Input className='formItemHiddenStyle' />
            </Form.Item>
            <Form.Item noStyle>
              <Upload {...importPropsServerFile} listType="text" maxCount={1} accept='.pfx' action={`${KAPTAIN_URL}/configuration/sys/upload/file/server.pfx`}><Button icon={<UploadOutlined />}>upload</Button></Upload>
              {
                serverPFXHas
                ? <div className='fileListShow'><CheckCircleOutlined style={{marginRight: 7.5, color: '#52c41a'}}/>{serverPFXHas}</div>
                : null
              }
            </Form.Item>
          </Form.Item> : null}
          {/* server_key.pem && server.pem */}
          {!usePfxCertificateDisabled && pfxPasswordShow ? <Form.Item label="Server PEM File">
            <Form.Item
              label="Server PEM File"
              name="temp-serverPEMHas"
              noStyle
              rules={[{ required: true }]}
            >
              <Input className='formItemHiddenStyle' />
            </Form.Item>
            <Form.Item noStyle>
              <Upload {...importPropsServerFile} listType="text" maxCount={1} accept='.pem' action={`${KAPTAIN_URL}/configuration/sys/upload/file/server_key.pem`}><Button icon={<UploadOutlined />}>upload</Button></Upload>
              {
                serverPEMHas
                ? <div className='fileListShow'><CheckCircleOutlined style={{marginRight: 7.5, color: '#52c41a'}}/>{serverPEMHas}</div>
                : null
              }
            </Form.Item>
          </Form.Item> : null}
          {!usePfxCertificateDisabled && pfxPasswordShow ? <Form.Item label="ServerKey PEM File">
            <Form.Item
              label="ServerKey PEM File"
              name="temp-serverKeyPEMHas"
              noStyle
              rules={[{ required: true }]}
            >
              <Input className='formItemHiddenStyle' />
            </Form.Item>
            <Form.Item noStyle>
              <Upload {...importPropsServerFile} listType="text" maxCount={1} accept='.pem' action={`${KAPTAIN_URL}/configuration/sys/upload/file/server.pem`}><Button icon={<UploadOutlined />}>upload</Button></Upload>
              {
                serverKeyPEMHas
                ? <div className='fileListShow'><CheckCircleOutlined style={{marginRight: 7.5, color: '#52c41a'}}/>{serverKeyPEMHas}</div>
                : null
              }
            </Form.Item>
          </Form.Item> : null}

          <Form.Item
            label='PFX Password'
            name={['settings', 'pfxPassword']}
            rules={[{ required: !pfxPasswordShow }]}
            hidden={pfxPasswordShow}
          >
            {/* (only when https = true and usepfxCertificate = true) */}
            <Input.Password disabled={pfxPasswordShow} autoComplete='new-password' />
          </Form.Item>
          <Form.Item
            label={<span>Qlik Hub URL</span>}
            name={['settings', 'corsAllowOrigins']}
          >
            {/* <Input /> */}
            <Select mode="tags" dropdownStyle={{display: 'none'}}></Select>
          </Form.Item>
          <Form.Item
            label={<span>Enable Apikey For Komment</span>}
            name={['settings', 'apiKey']}
            valuePropName="checked"
          >
            <Checkbox>
              <span style={{color: '#bfbfbf', fontStyle: 'italic'}}>If checked, apikey will be used to protect komment&apos;s api security</span>
            </Checkbox>
          </Form.Item>

        {/* Part 2 */}
          <Form.Item label={<span id='qlik-connection'>Qlik Connection</span>} style={{fontWeight: 700}} />
          <Form.Item
            label={<span>Qlik Connection Mode</span>}
            name={['settings', 'qlikHost']}
            initialValue={mode}
          >
            {/* (values: Certificate (default), JWT, SAAS, DESKTOP) */}
            <Select onSelect={(value) => handleSelectMode(value)}>
              <Option value="CERT">Certificate</Option>
              <Option value="JWT">JWT</Option>
              <Option value="SAAS">SaaS</Option>
              <Option value="DESKTOP">Desktop</Option>
            </Select>
          </Form.Item>
          {/* IF show */}
          {showModeItem()}
        
        {/* Part 3 */}
          <Form.Item label={<span id='db-settings'>Kaptain DB setttings</span>} style={{fontWeight: 700}} />
          <Form.Item
            label="Host"
            name={['db', 'KAPTAIN_DB_HOST']}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Port"
            name={['db', 'KAPTAIN_DB_PORT']}
            rules={[{ required: true }, { type: 'number' }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="User"
            name={['db', 'KAPTAIN_DB_USER']}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name={['db', 'KAPTAIN_DB_PASS']}
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Database"
            name={['db', 'KAPTAIN_DB_NAME']}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Don't show in Komment"
            name={['db', 'DO_NOT_SHOW_IN_KOMMENT']}
            valuePropName="checked"
          >
            <Checkbox>
              <span style={{color: '#bfbfbf', fontStyle: 'italic'}}>If checked, it will not be shown in Komment when creates a kapsule</span>
            </Checkbox>
          </Form.Item>

        {/* Part 4 */}
          <Form.Item label={<span id='datasource-list'>Data Source List</span>} style={{fontWeight: 700}}>
            <Button icon={<PlusCircleOutlined />} onClick={() => {dataSourceForm.resetFields(); setIsDataSourceModalVisible(true);}}>Add Data Source</Button>
          </Form.Item>
          <Form.Item {...FORM_LAYOUT_OFFSET_8_10}>
          <Tabs type="editable-card" hideAdd onEdit={(e, action) => onEdit(e, action)}>
            {
              Object.keys(dataSourceList).map((key: string) => {
                if ((dataSourceList as any)[key]) {
                  return renderDBItem(key);
                }
              })
            }
          </Tabs>
          </Form.Item>

        {/* Part 5 */}
          <Form.Item label={<span id='license'>License</span>} style={{fontWeight: 700}} />
          <Form.Item
            label="license"
            name={['license', 'LICENSE_TEXT']}
          >
            <Input.TextArea />
          </Form.Item>
          
        {/* Part 6 */}
          <Form.Item label={<span id='logs-settings'>Logs</span>} style={{fontWeight: 700}} />
          <Form.Item
            label="Log Level"
            name={['system', 'logLevel']}
          >
            <Select>
              <Option value="debug">debug</Option>
              <Option value="warn">warn</Option>
              <Option value="info">info</Option>
              <Option value="error">error</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Log Path"
            name={['system', 'logPath']}
          >
            <Input />
          </Form.Item>

          <Form.Item {...FORM_LAYOUT_OFFSET_8_10}>
            <Space size='large'>
              <Button htmlType="button" onClick={() => {reloadData(settingForm)}}>
                Reset
              </Button>
              <Button type="primary" htmlType="submit" id='submit'>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
      <Col span={6} style={{textAlign: 'left'}}>
        <Anchor>
          <Link href="#system-settings" title="System" />
          <Link href="#qlik-connection" title="Qlik Connection" />
          <Link href="#db-settings" title="Kaptain DB setttings" />
          <Link href="#datasource-list" title="Data Source List" />
          <Link href="#license" title="License" />
          <Link href="#logs-settings" title="Logs" />
          <Link href="#submit" title={<span style={{color: '#1890ff', fontWeight: 600}}>Submit</span>} />
        </Anchor>
      </Col>
      <BackTop />

      {/* add datasource */}
      <Modal
        title='Add Data Source'
        visible={isDataSourceModalVisible}
        onCancel={handleDataSourceModalCancel}
        onOk={handleDataSourceModalOk}
      >
        <Form {...FORM_LAYOUT_8_16} name="add-datasource" form={dataSourceForm}>
          <Form.Item name="dataSourceType" label="Data Source Type" rules={[{required: true, message: 'Please Select Data Source Type!'}]}>
            <Select>
              {!dataSourceList.mssql ? <Option value="mssql">MS SQL Server</Option> : null}
              {!dataSourceList.mysql ? <Option value="mysql">MySQL</Option> : null}
              {!dataSourceList.oracle ? <Option value="oracle">Oracle</Option> : null}
              {!dataSourceList.postgresql ? <Option value="postgresql">PostgreSQL</Option> : null}
              {!dataSourceList.snowflake ? <Option value="snowflake">Snowflake</Option> : null}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
    </Spin>
  );
};
