import { toDate, format } from 'date-fns-tz';

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const stringToUTC = (dateStr: string): number => {
  return toDate(dateStr).getTime();
};

export const formatToLocale = (timestamp: number, dateFormat: string): string => {
  return format(timestamp, dateFormat, { timeZone: localTimezone });
};
