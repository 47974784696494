import React, { FunctionComponent, useEffect, useState } from 'react';
import { Form, Input, Button, message, notification, Typography } from 'antd';
import { UserOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import './Login.css';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useHistory } from 'react-router-dom';
import { fetchSystemStatus, initUser, login, SystemStatus, fetchAPPVersion } from '../../services/AuthService';
import logo from './ExtendBI.png';
import { User } from '../../types/User';

const { Text } = Typography;

type LoginProps = {
  onLogin: (user: User) => void;
};

type LoginData = {
  username: string;
  password: string;
};

type RegisterData = {
  username: string;
  password: string;
  confirmPassword: string;
};

export const Login: FunctionComponent<LoginProps> = ({ onLogin }) => {
  const size = useWindowSize();
  const history = useHistory();
  const [systemStatus, setSystemStatus] = useState<SystemStatus>('initialized');
  const [appVersion, setAppVersion] = useState<string>('')

  useEffect(() => {
    getSystemStatus();
    getAPPVersion();
  }, []);

  const getAPPVersion = async () => {
    try {
      const version = await fetchAPPVersion();
      setAppVersion(version);
    } catch (e) {
      setAppVersion('unknown version');
    }
  }
  
  const getSystemStatus = async () => {
    try {
      const status = await fetchSystemStatus();
      setSystemStatus(status);
    } catch (e) {
      setSystemStatus('initialized');
    }
  };

  const onRegister = async (values: RegisterData) => {
    try {
      const createStatus = await initUser(values.username, values.password);
      if (createStatus === 'adminExist') {
        notification.info({
          message: 'Admin already exist',
          description: 'Please login and use Kaptain with admin user!',
        });
      }
      setSystemStatus('initialized');
    } catch (e) {
      notification.error({
        message: 'Create admin failed',
        description: 'Please try again!',
      });
      setSystemStatus('initializationNeeded');
    }
  };

  const onFinish = async (values: LoginData) => {
    const { username, password } = values;

    try {
      const data = await login(username, password);
      localStorage.setItem('token', data.accessToken);
      localStorage.setItem('user', JSON.stringify(data.user));
      onLogin(data.user);
      history.push('/');
    } catch (error) {
      message.error(`Login Failed: ${error.message}`);
      console.log(error);
    }
  };
  return (
    <div className="login-container" style={{ height: size.height || 600 }}>
      <div className="login-wrapper">
        <img src={logo} alt="logo" className="extend-bi-logo" />
        <div className='login-version'>
          {appVersion}
        </div>
        {systemStatus === 'initialized' && (
          <Form name="normal_login" className="login-form" initialValues={{}} onFinish={onFinish}>
            <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                iconRender={(visible) => {
                  if (visible) {
                    return <EyeTwoTone />;
                  } else {
                    return <EyeInvisibleOutlined />;
                  }
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Log in
              </Button>
            </Form.Item>
          </Form>
        )}
        {systemStatus === 'initializationNeeded' && (
          <Form name="register-user" className="login-form" initialValues={{ username: 'admin' }} onFinish={onRegister}>
            <Form.Item>
              <Text type="danger">Initialize admin user to start using Kaptain</Text>
            </Form.Item>

            <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
              <Input disabled prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
                ({}) => ({
                  validator(_, value) {
                    if (!value || value.length >= 8) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Please use at least 8 characters as password!'));
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                iconRender={(visible) => {
                  if (visible) {
                    return <EyeTwoTone />;
                  } else {
                    return <EyeInvisibleOutlined />;
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                { required: true, message: 'Please input your Password again!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Confirm Password"
                iconRender={(visible) => {
                  if (visible) {
                    return <EyeTwoTone />;
                  } else {
                    return <EyeInvisibleOutlined />;
                  }
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Set Password
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};
