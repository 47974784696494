export const FORM_LAYOUT_8_16 = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const FORM_LAYOUT_8_10 = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};
export const FORM_LAYOUT_OFFSET_8_10 = {
  wrapperCol: { offset: 8, span: 10 },
};

export const FORM_LAYOUT_4_20 = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const FORM_LAYOUT_OFFSET_8_16 = {
  wrapperCol: { offset: 8, span: 16 },
};

export const FORM_VALIDATE_MESSAGES = {
  required: '${label} is required!',
  types: {
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
