import { Modal, Space, Spin, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Log } from '../../types/Log';
import { downloadLogFile, getLogList } from '../../services/LogService';
import { exportKapsule } from '../../services/KapsuleService';
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';

type LogTableProps = {};

const logListSearchTextFilter = (record: Log, searchText: string): boolean => {
  if (searchText === '') return true;
  return record.name.toLowerCase().includes(searchText);
};

export const LogTable: FunctionComponent<LogTableProps> = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [logList, setLogList] = useState<Log[]>();

  useEffect(() => {
    reloadLogList();
  }, []);

  const reloadLogList = async () => {
    try {
      const logList = await getLogList();
      setLogList(logList);
    } catch (e) {
      setLogList([]);
    }
  };

  const onSearch = (searchText: string) => {
    if (searchText) {
      setSearchText(searchText.toLowerCase());
    } else {
      setSearchText('');
    }
  };

  const columns = [
    { title: 'Log ID', dataIndex: '_id' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Type', dataIndex: 'type' },
    { title: 'User ID',
      render(record: Log) {
        const desc =  record.data.qlikUser.userId;
        return <span>{desc}</span>
      }
    },
    { title: 'LastRunAt', dataIndex: 'lastRunAt' },
    { title: 'LastFinishedAt', dataIndex: 'lastFinishedAt' },
    { title: 'timeConsuming', dataIndex: 'timeConsuming',
      render: (item: string) => `${item} ms`
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_text: string, record: Log) => (
        <Space size="middle">
          {record.data.qScriptLogFile && (
            <Tooltip title="Download ScriptLogFile">
              <DownloadOutlined
                style={{ color: '#1890ff' }}
                onClick={() => {
                  console.log('download', record.data?.qScriptLogFile)
                  downloadLogFile(record._id);
                }}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return logList ? (
    <>
      <Table
        title={() => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <b>Logs</b>
            <Space>
              <Search placeholder="Search by log name" allowClear onSearch={onSearch} style={{ width: 200 }} />
            </Space>
          </div>
        )}
        expandable={{
          expandedRowRender: (record) => {
            const text = JSON.stringify(record, undefined, 4);
            return (
              <pre>
                <code>{text}</code>
              </pre>
            );
          },
          rowExpandable: (record) => !!record,
        }}
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={logList.filter((item) => logListSearchTextFilter(item, searchText))}
      />
    </>
  ) : (
    <div style={{ height: 312, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin />
    </div>
  );
};
