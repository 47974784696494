import { Role } from '../enums/Role';
import { Group } from '../types/Group';
import { User } from '../types/User';

export const transformUser = (userId: string, username: string, role: string, groups: Group[], qlikId: string, isCreate: boolean): User => {
  return {
    userId,
    username,
    role: role === 'ADMIN' ? Role.ADMIN : Role.USER,
    groups,
    qlikId,
    isCreate
  };
};
