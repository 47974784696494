import axios from 'axios';
import { Apikey } from '../types/Apikey';
import { formatToLocale, stringToUTC } from '../utils/dateUtils';
import { KAPTAIN_URL } from './config';

export const getApiKeyList = async (): Promise<Apikey[]> => {
  const response = await axios.get<Apikey[]>(`${KAPTAIN_URL}/api/privatekey/list`);
  const raw = response.data;
  return raw.map((item) => {
    return {
      ...item,
      createdAt: formatToLocale(stringToUTC(item.createdAt), 'yyyy-MM-dd HH:mm:ss'),
    };
  });
};

export const getApiKeyForOne = async (): Promise<string> => {
    const response = await axios.get<string>(`${KAPTAIN_URL}/api/privatekey/get`);
    return response.data;
};

type CreateApiKeyInput = {
    name: string;
    apikey: string;
};
export const createApiKey = async (input: CreateApiKeyInput) => {
  return new Promise((resolve, reject) => {
    axios.post<{ status: string }>(`${KAPTAIN_URL}/api/privatekey/create`,  input)
    .then((res) => {
      resolve(res)
    })
    .catch((err) => {
      reject(err)
    })
  });
};

export const deleteApiKey = async (apiKeyId: string) => {
  await axios.delete(`${KAPTAIN_URL}/api/privatekey/del/${apiKeyId}`);
};