import {
  DeleteOutlined,
  FolderOpenOutlined,
  InfoCircleOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { Space, Table, Modal, Spin, Button, Upload, message, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { FunctionComponent, useEffect, useState, useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { GroupAuthority } from '../../enums/GroupAuthority';
import { deleteKapsules, getKapsules , exportKapsule, upgradeGranulesForKapsules} from '../../services/KapsuleService';
import { Kapsule } from '../../types/Kapsule';
import { UserContext } from '../../App';
import { KapsuleFieldsTable } from '../kapsuleFieldsTable/KapsuleFieldsTable';
import { KAPTAIN_URL } from '../../services/config';
import UploadOutlined from '@ant-design/icons/lib/icons/UploadOutlined';
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';
import FolderAddOutlined from '@ant-design/icons/lib/icons/FolderAddOutlined';
import CheckCircleTwoTone from '@ant-design/icons/lib/icons/CheckCircleTwoTone';
import CloseCircleTwoTone from '@ant-design/icons/lib/icons/CloseCircleTwoTone';
import { Role } from '../../enums/Role';
import IconFont from '../iconFont/IconFont';

type KapsulesTableProps = {};

const openKapsuleInfoModal = (record: Kapsule) => {
  Modal.info({
    title: record.name,
    maskClosable: true,
    content: <KapsuleFieldsTable fields={record.fields} />,
    onOk() { },
  });
};

const kapsulesSearchTextFilter = (
  record: Kapsule,
  searchText: string
): boolean => {
  if (searchText === '') return true;
  return (
    record.name.toLowerCase().includes(searchText) ||
    record.table.toLowerCase().includes(searchText)
  );
};

const iconFontShow = (database: Kapsule) => {
  return (
    <IconFont type={`icon-${database.database}`} style={{ fontSize: 19 }} />
  )
}

export const KapsulesTable: FunctionComponent<KapsulesTableProps> = () => {
  const user = useContext(UserContext);
  const [searchText, setSearchText] = useState<string>('');

  // 批量导入结果展示窗
  const [isShowResult, showResultBox] = useState<boolean>(false);
  const [fileListImportResult, setFileListImportResult] = useState<any>([]);
  // 批量删除结果展示窗
  const [isShowDeleteResult, showDeleteResultBox] = useState<boolean>(false);
  const [deleteListResult, setDeleteListResult] = useState<any>([]);

  const { url } = useRouteMatch();

  const importPropsForOne = {
    name: 'file',
    accept: '.kap',
    // action: `${KAPTAIN_URL}/api/kapsule/import/kapsule`,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    showUploadList: false,
    beforeUpload: () => {
      return new Promise<void>((resolve, reject) => {
        Modal.confirm({
          title: 'Confirm',
          content: (<div><span>This operation will overwrite the kapsule, do you want to continue?</span><br /><span>Only the kapsule structure will be changed. No data will be altered.</span></div>),
          okText: 'Yes',
          cancelText: 'Cancel',
          onOk: async () => {
            resolve()
          },
          onCancel: async () => {
            reject()
          }
        }
        )
      })
    },
    onChange(info: any) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} import successfully`);
        reloadKapsules();
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} import failed, log: ${info.file.response.message}`);
      }
    },
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: Kapsule, b: Kapsule) => a.name.localeCompare(b.name),
    },
    {
      title: 'Database',
      dataIndex: 'database',
      render: (_text: string, record: Kapsule) => (
        <Space size="small">
          {iconFontShow(record)}
          <span>{record.database}</span>
        </Space>
      )
    },
    {
      title: 'Table Name',
      dataIndex: 'tableName'
    },
    {
      title: 'ID',
      dataIndex: '_id',
      sorter: (a: Kapsule, b: Kapsule) => a._id.localeCompare(b._id),
    },
    {
      title: 'Binding Komment Table',
      dataIndex: 'table',
      sorter: (a: Kapsule, b: Kapsule) => a.table.localeCompare(b.table),
    },
    {
      title: 'Key Field',
      dataIndex: 'keyField',
      sorter: (a: Kapsule, b: Kapsule) => a.keyField.localeCompare(b.keyField),
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      sorter: (a: Kapsule, b: Kapsule) =>
        (a.owner || '').localeCompare(b.owner),
    },
    {
      title: 'Last Update',
      dataIndex: 'updatedAt',
      sorter: (a: Kapsule, b: Kapsule) => {
        const aTime = new Date(a.updatedAt).getTime() || 0;
        const bTime = new Date(b.updatedAt).getTime() || 0;
        return aTime - bTime;
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_text: string, record: Kapsule) => (
        <Space size="middle">
          <Tooltip title="Open Kapsule">
            <Link to={`${url}/${record._id}`}>
              <FolderOpenOutlined />
            </Link>
          </Tooltip>
          <Tooltip title="Kapsule Structure">
            <InfoCircleOutlined
              style={{ color: '#1890ff' }}
              onClick={() => openKapsuleInfoModal(record)}
            />
          </Tooltip>
          {
            (
              record.authority?.includes(GroupAuthority.DELETE) ||
              (user && user.role === Role.ADMIN)
            ) ? (
              <Tooltip title="Delete Kapsule">
                <DeleteOutlined
                  style={{ color: '#1890ff' }}
                  onClick={() => {
                    Modal.confirm({
                      title: 'Confirm',
                      content: `Are you sure to delete ${record.name}?`,
                      okText: 'Yes',
                      cancelText: 'Cancel',
                      onOk: async () => {
                        await deleteKapsules([record._id]);
                        reloadKapsules();
                      },
                    });
                  }}
                />
              </Tooltip>
            ) : null}
          <Tooltip title="Export Kapsule">
            <DownloadOutlined
              style={{ color: '#1890ff' }}
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm',
                  content: `Are you sure to export ${record.name}.kap?`,
                  okText: 'Yes',
                  cancelText: 'Cancel',
                  onOk: async () => {
                    await exportKapsule(record._id);
                  },
                });
              }}
            />
          </Tooltip>
          <Tooltip title="Replace Kapsule">
            <Upload
              {...importPropsForOne}
              action={`${KAPTAIN_URL}/api/kapsule/import/kapsule/${record._id}`}
            >
              <UploadOutlined style={{ color: '#1890ff', cursor: 'pointer' }} />
            </Upload>
          </Tooltip>
          {/* {user && user.role === Role.ADMIN && !record.version ? (
              <Tooltip title="Upgrade to the latest version">
                <SyncOutlined
                    style={{ color: '#1890ff' }}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Confirm',
                        content: `Are you sure to upgrade ${record.name}?`,
                        okText: 'Yes',
                        cancelText: 'Cancel',
                        onOk: async () => {
                          await upgradeGranulesForKapsules([record._id]);
                          reloadKapsules();
                        },
                      });
                    }}
                />
              </Tooltip>
          ) : null} */}
        </Space>
      ),
    },
  ];

  const [kapsules, setKapsules] = useState<Kapsule[]>();
  const [selectedKapsuleIds, setSelectedKapsuleIds] = useState<string[]>([]);
  useEffect(() => {
    reloadKapsules();
  }, []);

  const onSearch = (searchText: string) => {
    if (searchText) {
      setSearchText(searchText.toLowerCase());
    } else {
      setSearchText('');
    }
  };

  const reloadKapsules = async () => {
    try {
      const kapsules = await getKapsules();
      setKapsules(kapsules);
    } catch (e) {
      message.error('Kapsules load Failed! Check console for log');
      console.error(e);
      setKapsules([]);
    }
  };

  const rowSelection = {
    onChange: (_selectedRowKeys: React.Key[], selectedRows: Kapsule[]) => {
      const selectedIds = selectedRows.map((item) => item._id);
      setSelectedKapsuleIds(selectedIds);
    },
  };

  const importProps = {
    name: 'files',
    accept: '.kap',
    action: `${KAPTAIN_URL}/api/kapsule/import/kapsules`,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    showUploadList: false,
    multiple: true,
    onChange: async (info: any) => {
      console.log('==>', fileListImportResult)
      // 打开结果展示窗
      if (!isShowResult) showResultBox(true)
      if (info.file.status === 'done') {
        const res = {
          success: true,
          msg: `${info.file.name} import successfully`
        }
        fileListImportResult.push(res)
        await setFileListImportResult([...fileListImportResult])
      } else if (info.file.status === 'error') {
        const res = {
          success: false,
          msg: `${info.file.name} import failed, log: ${info.file.response.message}`
        }
        fileListImportResult.push(res)
        await setFileListImportResult([...fileListImportResult])
      }
    },
  };

  return kapsules ? (
    <div>
      <Table
        title={() => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <b>Kapsules</b>
            <Space>
              <Upload {...importProps}>
                <Button icon={<FolderAddOutlined />}>Add</Button>
              </Upload>
              {/* <Button
                icon={<DownloadOutlined />}
                disabled={selectedKapsuleIds.length === 0}
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm',
                    content: `Are you sure to export selected Kapsules?`,
                    okText: 'Yes',
                    cancelText: 'Cancel',
                    onOk: async () => {
                      await setSelectedKapsuleIds([]);
                      await exportKapsules(selectedKapsuleIds);
                    },
                  });
                }}
              >
                Export
              </Button> */}
              <Button
                disabled={selectedKapsuleIds.length === 0}
                danger
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm',
                    content: `Are you sure to delete selected Kapsules?`,
                    okText: 'Yes',
                    cancelText: 'Cancel',
                    onOk: async () => {
                      await deleteKapsules(selectedKapsuleIds)
                        .then((res) => {
                          setSelectedKapsuleIds([]);
                          reloadKapsules();
                        })
                        .catch((err) => {
                          const kaps = JSON.parse(err.data && err.data.message)
                          if (kaps.length > 0) {
                            setDeleteListResult(kaps)
                            showDeleteResultBox(true)
                          }
                        })
                    },
                  });
                }}
              >
                Delete Selection
              </Button>
              <Search
                placeholder="input search text"
                allowClear
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </Space>
          </div>
        )}
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={kapsules.filter((item) =>
          kapsulesSearchTextFilter(item, searchText)
        )}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
      <Modal
        title="Import Result"
        visible={isShowResult}
        footer={null}
        maskClosable={false}
        onCancel={() => { showResultBox(false); reloadKapsules(); setFileListImportResult([]); }}
        bodyStyle={{ maxHeight: '60vh', overflowY: 'auto' }}
      >
        {
          fileListImportResult.map((e: any, i: number) => {
            if (e.success) {
              return (<p key={e.success + i}><CheckCircleTwoTone twoToneColor="#52c41a" /> {e.msg}</p>)
            } else {
              return (<p key={e.success + i}><CloseCircleTwoTone twoToneColor="#f04134" /> {e.msg}</p>)
            }
          })
        }
      </Modal>

      {/* 批量删除失败弹窗 */}
      <Modal
        title="Failed To Delete"
        visible={isShowDeleteResult}
        footer={null}
        onCancel={() => { showDeleteResultBox(false); setDeleteListResult([]); }}
        bodyStyle={{ maxHeight: '60vh', overflowY: 'auto' }}
      >
        <p><CloseCircleTwoTone twoToneColor="#f04134" /> You do not have delete permission for the following kapsules:</p>
        {
          deleteListResult.map((e: any, i: number) => {
            return (<p key={e + i}>{i + 1}. {e}</p>)
          })
        }
      </Modal>
    </div>
  ) : (
    <div
      style={{
        height: 312,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin />
    </div>
  );
};
