import axios from 'axios';
import { Group } from '../types/Group';
import { User } from '../types/User';
import { transformUser } from '../utils/transformUser';
import { KAPTAIN_URL } from './config';

export type SystemStatus = 'initialized' | 'initializationNeeded';

type LoginResponse = {
  accessToken: string;
  user: User;
};
export type UserDTO = {
  userId: string;
  username: string;
  role: string;
  groups: Group[];
  qlikId: string;
  isCreate: boolean;
};
export const login = async (username: string, password: string): Promise<LoginResponse> => {
  const response = await axios.post<{ accessToken: string; user: UserDTO }>(`${KAPTAIN_URL}/auth/login`, {
    username,
    password,
  });
  return {
    accessToken: response.data.accessToken,
    user: transformUser(response.data.user.userId, response.data.user.username, response.data.user.role, [], '', true),
  };
};

export const fetchSystemStatus = async (): Promise<SystemStatus> => {
  const response = await axios.get<{ status: SystemStatus }>(`${KAPTAIN_URL}/api/user/status`);
  const raw = response.data;
  return raw.status;
};

export const initUser = async (username: string, password: string) => {
  const response = await axios.post<{ status: string }>(`${KAPTAIN_URL}/api/user/init`, {
    username,
    password,
  });
  return response.data.status;
};

export const fetchAPPVersion = async (): Promise<string> => {
  const response = await axios.get<string>(`${KAPTAIN_URL}/app/version`);
  const raw = response.data;
  return raw;
};
