import React, { createContext, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import './App.css';
import { PrivateRoute } from './components/privateRoute/PrivateRoute';
import { Home } from './pages/home/Home';
import { Login } from './pages/login/Login';
import axios from 'axios';
import { User } from './types/User';
import { message } from 'antd';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.setItem('token', '');
      window.location.href = '/login';
    }
    return Promise.reject(error.response);
  }
);

export const UserContext = createContext<User | undefined>(undefined);

function App() {
  const [user, setUser] = useState<User>();
  const location = useLocation();

  useEffect(() => {
    if (location && location.pathname !== '/login' && !user) {
      const userData = localStorage.getItem('user');
      if (!userData) {
        localStorage.setItem('token', '');
        window.location.href = '/login';
      } else {
        const user = (JSON.parse(userData) as unknown) as User;
        setUser(user);
      }
    }
  }, [location, user]);

  return (
    <div className="App">
      <Switch>
        <Route path="/login">
          <Login onLogin={(user) => setUser(user)} />
        </Route>
        <PrivateRoute path="/">
          <UserContext.Provider value={user}>
            <Home />
          </UserContext.Provider>
        </PrivateRoute>
      </Switch>
    </div>
  );
}

export default App;
