import axios from 'axios';
import { Role } from '../enums/Role';
import { User } from '../types/User';
import { Group } from '../types/Group';
import { transformUser } from '../utils/transformUser';
import { UserDTO } from './AuthService';
import { KAPTAIN_URL } from './config';

export const getUserList = async (): Promise<User[]> => {
  const response = await axios.get<{ data: UserDTO[] }>(`${KAPTAIN_URL}/api/user/list`);
  return response.data.data.map((item) => transformUser(item.userId, item.username, item.role, item.groups, item.qlikId, item.isCreate));
};
export const getUserGroups = async (userId: string): Promise<Group[]> => {
  const response = await axios.get(`${KAPTAIN_URL}/api/group/findByUser/${userId}`);
  return response.data;
};

export const deleteUser = async (userId: string) => {
  await axios.delete(`${KAPTAIN_URL}/api/user/${userId}`);
};

type CreateUserInput = {
  username: string;
  password: string;
  role: Role;
  groups: Group[];
  qlikId: string;
  isCreate: boolean;
};
export const createUser = async (input: CreateUserInput) => {
  const response = await axios.post<{ status: string }>(`${KAPTAIN_URL}/api/user/create`, input);
  return response.data.status;
};

type UpdateUserInput = {
  userId: string;
  password?: string;
  role: Role;
  groups: Group[];
  qlikId: string;
  isCreate: boolean;
};
export const updateUser = async (input: UpdateUserInput) => {
  const response = await axios.put<{ status: string }>(`${KAPTAIN_URL}/api/user/update`, input);
  return response.data.status;
};
