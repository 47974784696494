import axios from 'axios';
import { Group } from '../types/Group';
import { Kapsule } from '../types/Kapsule';
import { User } from '../types/User';
import { KAPTAIN_URL } from './config';

export const getGroupList = async (): Promise<Group[]> => {
  const response = await axios.get<{ data: Group[] }>(`${KAPTAIN_URL}/api/group/list`);
  return response.data as any;
};

export const deleteGroup = async (groupId: string) => {
  await axios.delete(`${KAPTAIN_URL}/api/group/del/${groupId}`);
};

type CreateGroupInput = {
  groupname: string,
  kapsules: Kapsule[],
  users: User[],
  authority: string[]
};
export const createGroup = async (input: CreateGroupInput) => {
  const response = await axios.post<{ status: string, message: string }>(`${KAPTAIN_URL}/api/group/create`, input);
  return response.data;
};

type UpdateGroupInput = {
  groupId: string,
  groupname: string,
  kapsules: Kapsule[],
  users: User[],
  authority: string[]
};
export const updateGroup = async (input: UpdateGroupInput) => {
  const response = await axios.put<{ status: string }>(`${KAPTAIN_URL}/api/group/update/${input.groupId}`, input);
  return response.data.status;
};
