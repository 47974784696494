import { Avatar, Dropdown, Layout, Menu, Typography } from 'antd';
import {
  TableOutlined,
  DatabaseOutlined,
  LogoutOutlined,
  UserOutlined,
  GroupOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  KeyOutlined
} from '@ant-design/icons';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, Link, Redirect, useHistory } from 'react-router-dom';
import './Home.css';
import { KapsulesTable } from '../../components/kapsulesTable/KapsulesTable';
import { Granules } from '../../components/granules/Granules';

import logo from './Komment.png';
import { UserContext } from '../../App';
import { Role } from '../../enums/Role';
import { UserTable } from '../../components/user/UserTable';
import { LogTable } from '../../components/log/LogTable';
import { GroupTable } from '../../components/group/GroupTable';
import { ApiKeyTable } from '../../components/apikey/ApiKeyTable';

import { User } from '../../types/User';
import { SettingComp } from '../../components/setting/SettingComp';
import { getSettings } from '../../services/SettingCompService';
import { DataSource } from '../../types/DataSource';

const { Header, Content, Sider } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

type HomeProps = {};

export const Home: FunctionComponent<HomeProps> = () => {
  const user = useContext(UserContext);
  const { path, url } = useRouteMatch();
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const [useApikey, setUseApikey] = useState(false);

  useEffect(() => {
    getServerSetting()
  }, []);

  /**
   * get server`s config.json
   */
   const getServerSetting = () => {
    //get data
    getSettings().then((res) => {
      setUseApikey(Boolean(res.settings.apiKey));
    }).catch(() => {
      setUseApikey(false);
    })
  }

  const logout = () => {
    window.localStorage.removeItem('token');
    history.push('/login');
  };

  const userData = localStorage.getItem('user') as string;
  const userInfo = (JSON.parse(userData) as unknown) as User;

  const menu = (
    <Menu>
      <div className="user-dropdown-heading">
        <Title level={5}>{userInfo?.username}</Title>
        {/* <Text type="secondary">6043aace14fd8416982c0295</Text> */}
      </div>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={logout}>
        <div className="user-dropdown-item">
          <LogoutOutlined />
          <span className="user-dropdown-text">Logout</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <div className="site-layout-background">
          <img className="sidebar-logo" src={logo} alt="logo" />
        </div>
        <Menu theme="dark" defaultOpenKeys={['data-management']} defaultSelectedKeys={['kapsules']} mode="inline">
          {/* <SubMenu key="dashboard" icon={<DashboardOutlined />} title="Dashboard"></SubMenu> */}
          <SubMenu key="data-management" icon={<DatabaseOutlined />} title="Data Management">
            <Menu.Item key="kapsules" icon={<TableOutlined />}>
              <Link to={`${url}data/kapsules`}>Kapsules</Link>
            </Menu.Item>
            <Menu.Item key="logs" icon={<UnorderedListOutlined />}>
              <Link to={`${url}system/logs`}>Logs</Link>
            </Menu.Item>
          </SubMenu>
          {user && user.role === Role.ADMIN && (
            <SubMenu key="system-management" icon={<SettingOutlined />} title="System Management">
              <Menu.Item key="users" icon={<UserOutlined />}>
                <Link to={`${url}system/users`}>Users</Link>
              </Menu.Item>
              <Menu.Item key="groups" icon={<GroupOutlined />}>
                <Link to={`${url}system/groups`}>Groups</Link>
              </Menu.Item>
              {useApikey ? <Menu.Item key="apikey" icon={<KeyOutlined />}>
                <Link to={`${url}data/apikey`}>Api Key</Link>
              </Menu.Item> : null}
              <Menu.Item key="setting" icon={<SettingOutlined />}>
                <Link to={`${url}data/setting`}>Settings</Link>
              </Menu.Item>
            </SubMenu>
          )}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: '0 20px', textAlign: 'right' }}>
          <Dropdown overlay={menu} trigger={['click']}>
            <Avatar style={{ color: '#fff', backgroundColor: '#1758EB', cursor: 'pointer' }}>
              {userInfo?.username}
            </Avatar>
          </Dropdown>
        </Header>
        <Content style={{ margin: '16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Switch>
              <Route path={`${path}data/kapsules/:kapsuleId`}>
                <Granules />
              </Route>
              <Route path={`${path}data/kapsules`}>
                <KapsulesTable />
              </Route>
              <Route path={`${path}system/users`}>
                <UserTable />
              </Route>
              <Route path={`${path}system/logs`}>
                <LogTable />
              </Route>
              <Route path={`${path}system/groups`}>
                <GroupTable />
              </Route>
              <Route path={`${path}data/setting`}>
                <SettingComp />
              </Route>
              {useApikey ? <Route path={`${path}data/apikey`}>
                <ApiKeyTable />
              </Route> : null}
              <Route render={() => <Redirect to={`${path}data/kapsules`} />} />
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
