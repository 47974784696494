import React, { FunctionComponent } from 'react';
import { Granule } from '../../types/Granule';
import './GranulesSummary.css';

type GranulesSummaryProps = {
  granules?: Granule[];
};

type GranulesSummaryBlockProps = {
  title: string;
  value: number;
  withBorders?: boolean;
};

const GranulesSummaryBlock: FunctionComponent<GranulesSummaryBlockProps> = ({ title, value, withBorders }) => {
  return (
    <div className={withBorders ? 'granules-summary-block with-borders' : 'granules-summary-block'}>
      {title}
      <br />
      <br />
      {value}
    </div>
  );
};

export const GranulesSummary: FunctionComponent<GranulesSummaryProps> = ({ granules }) => {
  if (!granules) return null;
  return (
    <div className="granules-summary-container">
      <GranulesSummaryBlock title="Total Records" value={granules.length} />
      <GranulesSummaryBlock withBorders title="Reloaded" value={granules.filter((item) => item.reloaded).length} />
      <GranulesSummaryBlock title="Not Reloaded" value={granules.filter((item) => !item.reloaded).length} />
    </div>
  );
};
